<template>
  <div class="detail" v-if="movie !== undefined">
    <div class="detail__video">
      <iframe :src="'https://player.vimeo.com/video/' + vimeoID + '?title=0&byline=0'"
      frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen :key="vimeoID"></iframe>
      <button class="detail__video__back" @click="back()">
        <img src="@/assets/img/arrow_foward-gray.svg" />
      </button>
    </div>
    <div class="detail__text">
      <h2 class="detail__text__title">{{ movie.name }}</h2>
      <div class="detail__text__teacher">
        <img class="detail__text__teacher__icon" :src="teacher.icon" />
        <p class="detail__text__teacher__name">{{ teacher.name }}</p>
      </div>
      <p class="detail__text__detail">{{ movie.detail }}</p>
      <!-- 日本語レベル -->
      <div class="detail__text__tag"
      v-for="level in movie.levels" :key="mid + level"
      @click="$router.push({ name: 'Search', query: { uid: uid, levels: level, topics: '', occupations: '', from: 'tag' }})">{{ level }}</div>
      <!-- 職業 -->
      <div class="detail__text__tag"
      v-for="occupation in movie.occupations" :key="mid + occupation"
      @click="$router.push({ name: 'Search', query: { uid: uid, levels: '', topics: '', occupations: occupation, from: 'tag' }})">{{ occupation }}</div>
      <!-- トピック -->
      <div class="detail__text__tag"
      v-for="topic in movie.topics" :key="mid + topic"
      @click="$router.push({ name: 'Search', query: { uid: uid, levels: '', topics: topic, occupations: '', from: 'tag' }})">{{ topic }}</div>
      <button :class="favoriteID.length > 0 ? 'detail__text__favorite--active' : 'detail__text__favorite'"
        @click="doFavrite" :disabled=isProcessingFavorited>
        <img class="detail__text__favorite__img" src="@/assets/img/heart-white.svg">
      </button>
      <div class="detail__text__line"></div>
    </div>
    <div class="detail__related">
      <h2 class="detail__related__title">Nội dung liên quan</h2>
      <video-list v-if="relatedMovies.length > 0" :movies=relatedMovies></video-list>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Player from '@vimeo/player'

import VideoList from '@/components/video/list'

export default {
  components: { VideoList },
  props: {
  },
  data () {
    return {
      // 動画のお気に入りドキュメントのID
      // IDがあればお気に入り中
      favoriteID: '',
      // お気に入り処理中かどうか
      isProcessingFavorited: true,
      // 関連動画の一覧
      relatedMovies: []
    }
  },
  mounted () {
    // 動画がない場合（直接URLを叩かれたとき）
    if (this.movie === undefined) {
      this.$router.push({ name: 'Video' })
    } else {
      // お気に入り情報の取得
      this.addMovieFav({ uid: this.uid, mid: this.mid }).then(id => {
        this.favoriteID = id
        this.isProcessingFavorited = false
      })
      // 関連動画の取得
      this.addRelatedMovies({
        mid: this.mid,
        levels: this.movie.levels,
        occupations: this.movie.occupations,
        topics: this.movie.topics
      }).then(movies => {
        this.relatedMovies = movies
      })
    }
  },
  async beforeRouteUpdate (to, from, next) {
    // 閲覧履歴に対する処理が何かを取得
    const condition = await this.conditionWatchingHistory()
    if (condition === 'nothing') { // 何もしない
      next()
    } else {
      // 閲覧中の履歴を取得
      const id = await this.getWatchingMovieID({
        uid: this.uid,
        mid: from.params.id
      })
      if (id === null && condition === 'watching') { // 閲覧履歴がなく、閲覧中の場合
        // 閲覧履歴の作成
        this.createWatchingMovie({
          uid: this.uid,
          mid: from.params.id,
          createdAt: new Date(),
          updatedAt: new Date()
        }).then(() => { next() })
      } else if (id === null && condition === 'finished') { // 閲覧履歴がなく、閲覧終了の場合
        // 何もしない
        next()
      } else if (id !== null && condition === 'watching') { // 閲覧履歴があり、閲覧中の場合
        // 閲覧履歴の更新
        this.updateWatchingMovie({
          id: id,
          updatedAt: new Date()
        }).then(() => { next() })
      } else if (id !== null && condition === 'finished') { // 閲覧履歴があり、閲覧終了の場合
        // 閲覧履歴の削除
        this.deleteWatchingMovie(id).then(() => { next() })
      } else {
        // エラーに飛ばす
        this.$router.push({ name: 'Error' })
      }
    }
  },
  async beforeRouteLeave (to, from, next) {
    // 閲覧履歴に対する処理が何かを取得
    const condition = await this.conditionWatchingHistory()
    if (condition === 'nothing') { // 何もしない
      next()
    } else {
      // 閲覧中の履歴を取得
      const id = await this.getWatchingMovieID({
        uid: this.uid,
        mid: from.params.id
      })
      if (id === null && condition === 'watching') { // 閲覧履歴がなく、閲覧中の場合
        // 閲覧履歴の作成
        this.createWatchingMovie({
          uid: this.uid,
          mid: from.params.id,
          createdAt: new Date(),
          updatedAt: new Date()
        }).then(() => { next() })
      } else if (id === null && condition === 'finished') { // 閲覧履歴がなく、閲覧終了の場合
        // 何もしない
        next()
      } else if (id !== null && condition === 'watching') { // 閲覧履歴があり、閲覧中の場合
        // 閲覧履歴の更新
        this.updateWatchingMovie({
          id: id,
          updatedAt: new Date()
        }).then(() => { next() })
      } else if (id !== null && condition === 'finished') { // 閲覧履歴があり、閲覧終了の場合
        // 閲覧履歴の削除
        this.deleteWatchingMovie(id).then(() => { next() })
      } else {
        // エラーに飛ばす
        this.$router.push({ name: 'Error' })
      }
    }
  },
  watch: {
    mid (newMID) {
      // 再度、関連動画を取得し直す
      this.relatedMovies = []
      this.addRelatedMovies({
        mid: newMID,
        levels: this.movie.levels,
        occupations: this.movie.occupations,
        topics: this.movie.topics
      }).then(movies => {
        this.relatedMovies = movies
      })

      // 再度、お気に入り情報を取得し直す
      this.favoriteID = ''
      this.isProcessingFavorited = true
      this.addMovieFav({ uid: this.uid, mid: newMID }).then(id => {
        this.favoriteID = id
        this.isProcessingFavorited = false
      })
    }
  },
  computed: {
    /**
     * @return {String} ユーザーID
     */
    uid () {
      return this.$store.getters['user/uid']
    },
    /**
     * @return {String} 動画のドキュメントID
     */
    mid () {
      return this.$route.params.id
    },
    /**
     * @return {Object} 動画オブジェクト
     */
    movie () {
      return this.getMovie()(this.mid)
    },
    /**
     * @return {String} vimeoのID
     */
    vimeoID () {
      return this.movie !== undefined ? this.movie.url.split('https://vimeo.com/')[1] : 0
    },
    /**
     * @return {Object} 講師オブジェクト
     */
    teacher () {
      return this.movie !== undefined ? this.getTeacher()(this.movie.teacher) : {}
    }
  },
  methods: {
    ...mapGetters('movie', ['getMovie']),
    ...mapActions('movie', ['addRelatedMovies']),
    ...mapActions('favMovie', ['addMovieFav', 'doMovieFav']),
    ...mapGetters('teacher', ['getTeacher']),
    ...mapActions('watchingMovie', ['getWatchingMovieID', 'createWatchingMovie', 'updateWatchingMovie', 'deleteWatchingMovie']),
    /**
     * 戻る
     */
    back () {
      this.$router.go(-1)
    },
    /**
     * 閲覧履歴に対して、どんな処理をするか
     * @return {String} nothing: 何もしない, watching: 閲覧中, finished: 閲覧終了
     */
    async conditionWatchingHistory () {
      const condition = await new Promise(resolve => {
        const iframe = document.querySelector('iframe')
        if (iframe === null) { // 直接詳細に来た場合
          return resolve('nothing')
        } else {
          const player = new Player(iframe)

          Promise.all([player.getCurrentTime(), player.getDuration()]).then(responses => {
            const curentTime = responses[0]
            const duration = responses[1]

            if (duration - curentTime > 30 && curentTime > 0) {
              return resolve('watching')
            } else if (duration - curentTime <= 30 && curentTime > 0) {
              return resolve('finished')
            } else {
              return resolve('nothing')
            }
          })
        }
      })
      return condition
    },
    /**
     * お気にりの登録 or 解除
     */
    doFavrite () {
      this.isProcessingFavorited = true
      const param = this.favoriteID.length === 0 ? { id: '', uid: this.uid, mid: this.mid } : { id: this.favoriteID }
      this.doMovieFav(param).then(id => {
        this.favoriteID = id
        this.isProcessingFavorited = false
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/valiables.scss';

.detail {
  position: relative;
  width: 100%;
  margin-bottom: $footer_height;
  padding: 0 $main_padding;
  font-size: 0;
  &__video {
    position: relative;
    margin-left: -$main_padding;
    iframe {
      width: 100vw;
      height: 56.25vw;
    }
    &__back {
      position: absolute;
      top: $header_padding_top;
      left: $main_padding;
      height: 20px;
      width: 20px;
    }
  }
  &__text {
    position: relative;
    margin-top: 12px;
    width: 100%;
    &__title {
      width: calc(100% - 50px);
      font-size: 20px;
      font-weight: bold;
      color: #393939;
    }
    &__teacher {
      width: calc(100% - 50px);
      margin-top: 5px;
      &__icon {
        display: inline-block;
        vertical-align: middle;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        object-fit: cover;
      }
      &__name {
        display: inline-block;
        vertical-align: middle;
        margin: 0;
        margin-left: 5px;
        font-size: 12px;
        color: #707070;
      }
    }
    &__detail {
      margin: 5px 0;
      width: 100%;
      font-size: 12px;
      color: #707070;
    }
    &__tag {
      display: inline-block;
      vertical-align: top;
      height: 14px;
      min-width: 42px;
      margin-right: 5px;
      margin-bottom: 5px;
      padding: 0 8px;
      border-radius: 20px;
      background-color: #a5a5a5;
      font-size: 10px;
      line-height: 14px;
      color: white;
      text-align: center;
      box-sizing: border-box;
    }
    &__line {
      width: 100vw;
      margin-top: 13px;
      margin-left: -$main_padding;
      border-bottom: 1px solid #e0e0e0;
    }
    &__favorite {
      position: absolute;
      top: 0;
      right: 0;
      height: 40px;
      width: 40px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      background-color: #d5d5d5;
      border-radius: 50%;
      text-align: center;
      &--active {
        @extend .detail__text__favorite;
        background-color: #ffa758;
      }
      &__img {
        height: auto;
        width: 15.5px;
        margin-top: 3px;
      }
    }
  }
  &__related {
    margin-top: 25px;
    &__title {
      font-size: 18px;
      font-weight: bold;
      color: #393939;
    }
  }
}
</style>
